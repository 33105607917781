import { classed, type ComponentProps } from '@motion/theme'
import { range } from '@motion/utils/array'

export const SkeletonLine = classed('p', {
  base: `
    rounded-full
    bg-label-grey-bg
    w-full
    inline-flex
  `,
  variants: {
    size: {
      sm: 'h-[13px]',
      base: 'h-[16px]',
      lg: 'h-[18px]',
    },
  },
  defaultVariants: {
    size: 'base',
  },
})
export type SkeletonLineProps = ComponentProps<typeof SkeletonLine>

export type SkeletonBodyLinesProps = {
  lines?: number
  size?: SkeletonLineProps['size']
  gap?: SkeletonBodyLinesWrapperProps['gap']
}

export const SkeletonBodyLines = ({
  lines = 3,
  size = 'base',
  gap = 'base',
}: SkeletonBodyLinesProps) => {
  return (
    <SkeletonBodyLinesWrapper gap={gap}>
      {range(lines).map((l, i, arr) => {
        return (
          <SkeletonLine
            key={i}
            className={i + 1 === arr.length ? 'w-[80%]' : undefined}
            size={size}
          />
        )
      })}
    </SkeletonBodyLinesWrapper>
  )
}

type SkeletonBodyLinesWrapperProps = ComponentProps<
  typeof SkeletonBodyLinesWrapper
>

const SkeletonBodyLinesWrapper = classed('div', {
  base: 'flex flex-col w-full',
  variants: {
    gap: {
      sm: '[&>*]:my-[2px]',
      base: '[&>*]:my-[3px]',
      lg: '[&>*]:my-[5px]',
    },
  },
  defaultVariants: {
    gap: 'base',
  },
})
