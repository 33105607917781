import { maxBy } from '@motion/utils/array'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { hashKey } from '@tanstack/react-query'
import {
  type PersistedClient,
  type Persister,
} from '@tanstack/react-query-persist-client'

import { BUSTER, EAGER_LOAD_QUERY_KEYS } from './constants'

import { DB } from '../storage'
import {
  DB_VERSION,
  QUERY_PERSISTOR_MIN_DB_VERSION,
} from '../storage/db/constants'

const log = makeLog('query-persister')

const QUERY_CACHE_KEY = 'query'

export function createGlobalQueryPersister(key: string = QUERY_CACHE_KEY) {
  log('create', key)
  if (DB_VERSION < QUERY_PERSISTOR_MIN_DB_VERSION) {
    return noopPersister
  }
  return {
    persistClient: () => Promise.resolve(),
    restoreClient: async () => {
      // We only want to restore if we are using `indexedDB`

      return await log.time(
        `restore [${key}]`,
        async () => {
          return DB.open()
            .then<PersistedClient | undefined>(async () => {
              if (
                DB.provider !== 'indexedDB' ||
                DB.version < QUERY_PERSISTOR_MIN_DB_VERSION
              ) {
                log(
                  `persistor requires indexedDB >= ${QUERY_PERSISTOR_MIN_DB_VERSION}. Found '${DB.provider}@${DB.version}`
                )
                return undefined
              }

              const queriesMap = await DB.queryCache.getMany(
                EAGER_LOAD_QUERY_KEYS.map((x) => `query-${hashKey(x)}`)
              )
              const queryState = Object.values(queriesMap)
                .filter(Boolean)
                .filter((q) => q.buster === BUSTER)

              const maxTimestamp = maxBy(
                queryState,
                (x) => x.state.dataUpdatedAt
              )

              return {
                timestamp: maxTimestamp,
                buster: BUSTER,
                clientState: {
                  mutations: [],
                  queries: queryState,
                },
              }
            })
            .catch(reportError('restore', key, () => undefined))
        },
        () => ({ mark: Math.trunc(performance.now()) })
      )
    },
    removeClient: async () => {
      log('clearing')
      return DB.open()
        .then(() => {
          if (DB.provider !== 'indexedDB' || DB.version < 3) return
          return DB.queryCache.delete(key)
        })
        .catch(reportError('removeClient', key, () => undefined))
    },
  } as Persister
}

export const noopPersister = {
  persistClient: () => {},
  restoreClient: () => {},
  removeClient: () => {},
} as Persister

/**
 * Reports an error to Sentry
 * @param method the method that was invoked
 * @param key the persistance key
 * @param handler optional handler for error. Defaults to re-throw
 * @returns the result of the handler
 */
function reportError<T>(
  method: string,
  key: string,
  handler: (ex: unknown) => T = (ex) => {
    throw ex
  }
) {
  return (ex: unknown) => {
    log.error(method, key, ex)
    Sentry.captureException(ex, {
      tags: {
        position: 'query-persistor',
      },
      extra: {
        method,
        key,
      },
    })
    return handler(ex)
  }
}
