import { MODEL_CACHE_KEY } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import {
  MOTION_CLIENT_HEADER,
  MOTION_TIMEZONE_HEADER,
} from '@motion/shared/common'
import { getMotionClient } from '@motion/web-base/env'

import { type QueryKey } from '@tanstack/react-query'
import { DateTime } from 'luxon'

export const BUSTER = ''

export const STATIC_HEADERS = {
  'x-motion-web-version': __SENTRY_RELEASE__,
  [MOTION_CLIENT_HEADER]: getMotionClient(),
  [MOTION_TIMEZONE_HEADER]: DateTime.now().zoneName,
}

export const EAGER_LOAD_QUERY_KEYS: QueryKey[] = [
  MODEL_CACHE_KEY,
  API.users.postLoginApi.key(),
  API.usersV2.getCurrentUser.key(),
  API.usersV2.getFeaturePermissions.key(),
]
