import { createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import {
  type AccountCappedFeature,
  type CappedFeature,
  type OnOffFeature,
  type SizedFeature,
  type WorkspaceCappedFeature,
} from '@motion/shared/feature-tiers'
import { useCurrentUserOrNull } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'

import { useCallback } from 'react'

const useGetFeaturePermissions = createUseQuery(
  API.usersV2.getFeaturePermissions
)

export const useHasFeaturePermission = (feature: OnOffFeature): boolean => {
  const shouldFeatureGate = useShouldFeatureGate()

  const user = useCurrentUserOrNull()
  const featurePermissions = useGetFeaturePermissions(undefined, {
    enabled: !!user,
  })

  if (!user) {
    return false
  }

  if (!shouldFeatureGate) {
    return true
  }

  return !!featurePermissions.data?.models?.userFeaturePermissions?.[
    user.uid
  ]?.[feature]
}

/**
 * Same as useWillExceedAccountFeatureCap, but returns a callback function
 * that can be used to check if the usage will exceed the limit.
 *
 * Context: This is done to bypass the limitation of a hook only being able to be called at the top level of a component.
 * Example: attachment-upload-shell.tsx
 */
export const useCheckWillExceedAccountFeatureCap = (
  feature: AccountCappedFeature
): ((addedUsage: number) => boolean) => {
  const shouldFeatureGate = useShouldFeatureGate()
  const limit = useFeatureLimit(feature)
  const usage = useAccountFeatureUsage(feature)

  // Return a callback function that checks if the usage will exceed the limit
  return useCallback(
    (addedUsage: number) => {
      if (!shouldFeatureGate) {
        return false
      }
      return willExceed(usage, addedUsage, limit)
    },
    [shouldFeatureGate, usage, limit]
  )
}

export const useWillExceedAccountFeatureCap = (
  feature: AccountCappedFeature,
  addedUsage: number
): boolean => {
  const shouldFeatureGate = useShouldFeatureGate()
  const usage = useAccountFeatureUsage(feature)
  const limit = useFeatureLimit(feature)

  if (!shouldFeatureGate) {
    return false
  }

  return willExceed(usage, addedUsage, limit)
}

type useWillExceedWorkspaceFeatureCapProps = {
  feature: WorkspaceCappedFeature
  workspaceId: string | undefined
  addedUsage: number
}

export const useWillExceedWorkspaceFeatureCap = ({
  feature,
  workspaceId,
  addedUsage,
}: useWillExceedWorkspaceFeatureCapProps): boolean => {
  const shouldFeatureGate = useShouldFeatureGate()
  const usage = useWorkspaceFeatureUsage(feature, workspaceId)
  const limit = useFeatureLimit(feature)

  if (!shouldFeatureGate || !workspaceId) {
    return false
  }

  return willExceed(usage, addedUsage, limit)
}

const willExceed = (
  usage: number | undefined,
  addedUsage: number,
  limit: number
): boolean => {
  if (limit === -1 || addedUsage <= 0) {
    return false
  }
  if (usage == null) {
    return true
  }
  return usage + addedUsage > limit
}

export const useFeatureLimit = (
  feature: CappedFeature | SizedFeature,
  defaultValue = 0
): number => {
  const user = useCurrentUserOrNull()
  const featurePermissions = useGetFeaturePermissions(undefined, {
    enabled: !!user,
  })
  if (!user) {
    return defaultValue
  }
  return (
    featurePermissions.data?.models?.userFeaturePermissions?.[user.uid]?.[
      feature
    ] ?? defaultValue
  )
}

/**
 * Returns true if the feature limit is unlimited.
 * Useful to know if we should show the upsell button.
 */
export const useIsFeatureLimitUnlimited = (
  feature: CappedFeature | SizedFeature
): boolean => {
  const limit = useFeatureLimit(feature)
  return limit === -1
}

export const useAccountFeatureUsage = (
  feature: AccountCappedFeature
): number | undefined => {
  const user = useCurrentUserOrNull()
  const featurePermissions = useGetFeaturePermissions(undefined, {
    enabled: !!user,
  })
  if (!user) {
    return undefined
  }
  return featurePermissions.data?.models?.userFeaturePermissionUsages?.[
    user.uid
  ]?.[feature]
}

export const useWorkspaceFeatureUsage = (
  feature: WorkspaceCappedFeature,
  workspaceId: string | undefined
): number | undefined => {
  const featurePermissions = useGetFeaturePermissions(undefined, {
    enabled: !!workspaceId,
  })

  if (!workspaceId) {
    return undefined
  }

  return featurePermissions.data?.models?.workspaceFeaturePermissionUsages?.[
    workspaceId
  ]?.[feature]
}

export const useShouldFeatureGate = () => {
  const tierPricingEnabled = useHasTreatment('tiered-pricing-v1')
  const user = useCurrentUserOrNull()
  const featurePermissions = useGetFeaturePermissions(undefined, {
    enabled: !!user,
  })

  if (!user) {
    return false
  }

  const currentTier =
    featurePermissions.data?.models?.userFeaturePermissions?.[user.uid]?.tier
  const tierVersion =
    featurePermissions.data?.models?.userFeaturePermissions?.[user.uid]?.version

  // Only skip feature gating if the FF is off AND the user is on the legacy BASIC tier
  if (!tierPricingEnabled && currentTier === 'BASIC' && tierVersion === 0) {
    return false
  }

  return true
}

export type Feature = CappedFeature | OnOffFeature | SizedFeature
